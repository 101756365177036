
module.exports = {
  en: {
    alternateLang: function(d) { return "Français"; },
    "utils.priceOnRequest": function(d) { return "Price on request"; },
    "meta.description": function(d) { return "Quebec Housing"; },
    "notFound.doesNotExist": function(d) { return "This page does not exist"; },
    "notFound.returnToHome": function(d) { return "Click here to return to the home page."; },
    "cities.quebec": function(d) { return "Quebec City"; },
    "cities.montreal": function(d) { return "Montreal"; },
    "cities.sherbrooke": function(d) { return "Sherbrooke"; },
    "cities.trois-rivieres": function(d) { return "Three Rivers"; },
    "frontPage.lookingForApartment": function(d) { return "I'm looking for an apartment in..."; },
    "frontPage.lookingFor": function(d) { return "I'm looking to"; },
    "frontPage.rent": function(d) { return "rent"; },
    "frontPage.buy": function(d) { return "buy"; },
    "frontPage.colocation": function(d) { return "cohabitate"; },
    "frontPage.in": function(d) { return "in"; },
    "frontPage.letsGo": function(d) { return "Let's go!"; },
    "cityDisplay.inCity": function(d) { return "in " + d.city + "."; },
    "filter.title": function(d) { return "Filter"; },
    "filter.offersShown": function(d) { return "(" + d.numShown + " of " + d.numTotal + " offers shown)"; },
    "filters.priceRange": function(d) { return "Price range"; },
    "filters.min": function(d) { return "min"; },
    "filters.to": function(d) { return "to"; },
    "filters.max": function(d) { return "max"; },
    "filters.includeNullPrice": function(d) { return "Include offers with no listed price"; },
    "filters.timeOnMarket": function(d) { return "Time on the market"; },
    "filters.lessThan": function(d) { return "Less than"; },
    "filters.daysOld": function(d) { return "days old"; },
    "filters.numberOfRooms": function(d) { return "Number of rooms"; },
    "filters.oneOrTwoAndAHalf": function(d) { return "1 + 2 ½"; }
  },
  fr: {
    alternateLang: function(d) { return "English"; },
    "utils.priceOnRequest": function(d) { return "Prix sur demande"; },
    "meta.description": function(d) { return "Logement au québec"; },
    "notFound.doesNotExist": function(d) { return "Cette page n'existe pas"; },
    "notFound.returnToHome": function(d) { return "Cliquez içi pour vous rendre à la page d'accueil."; },
    "cities.quebec": function(d) { return "Québec"; },
    "cities.montreal": function(d) { return "Montréal"; },
    "cities.sherbrooke": function(d) { return "Sherbrooke"; },
    "cities.trois-rivieres": function(d) { return "Trois Rivières"; },
    "frontPage.lookingForApartment": function(d) { return "Je cherche un appartement à..."; },
    "frontPage.lookingFor": function(d) { return "Je compte"; },
    "frontPage.rent": function(d) { return "louer"; },
    "frontPage.buy": function(d) { return "acheter"; },
    "frontPage.colocation": function(d) { return "cohabiter"; },
    "frontPage.in": function(d) { return "à"; },
    "frontPage.letsGo": function(d) { return "Allons-y!"; },
    "cityDisplay.inCity": function(d) { return "à " + d.city + "."; },
    "filter.title": function(d) { return "Filtrer"; },
    "filter.offersShown": function(d) { return "(" + d.numShown + " de " + d.numTotal + " offres affichées)"; },
    "filters.priceRange": function(d) { return "Prix"; },
    "filters.min": function(d) { return "min"; },
    "filters.to": function(d) { return "à"; },
    "filters.max": function(d) { return "max"; },
    "filters.includeNullPrice": function(d) { return "Inclus les annonces sans prix indiqué"; },
    "filters.timeOnMarket": function(d) { return "Sur les marchés depuis..."; },
    "filters.lessThan": function(d) { return "Moins de"; },
    "filters.daysOld": function(d) { return "jours"; },
    "filters.numberOfRooms": function(d) { return "Nombre de pièces"; },
    "filters.oneOrTwoAndAHalf": function(d) { return "1 et 2 et demie"; }
  }
}